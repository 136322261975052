import React, { useMemo, useEffect, useCallback, useState, useRef, ReactElement } from "react";
import { Layout, Menu, Dropdown, Button } from "antd";

import bookRenderer from "../../BookRenderer";
import * as style from "./style.less";

import { SubToPages_pages } from "../../../api/SubToPages";
import { useI18nObjectHook } from "../../../../../../i18n";
import { SubToPageContent_page_rheses } from "../../../api/SubToPageContent";
import { checkIfSpace } from "../../../../types";
import { useIsContentAdministrator } from "../../../../../../identity";
import { SplitContent } from "../../EditingRouter/components/SplitContent";
import { getMenuItem, MenuItem } from "../../../../styles/antDesignTheme";
import {
  SubscribeToExtendedPage_extendedPage_paragraph,
  SubscribeToExtendedPage_extendedPage_rhese,
} from "../../../api/__generated__/SubscribeToExtendedPage";
import { SelectedTextPosition } from "../Formatting/components/FormattingComp/FormattingComp";
import { usePageData } from "../../../hooks/usePageData";
import { useCreateRheseFromPosition, useUpdateTextContent } from "../../../api/api";
import { getSelectedMetadata } from "../../../../utils/common";
import { UpdateTextContent } from "../../../../../../../__generated__/globalTypes";
import { useTextPosition } from "../../../hooks/useTextPosition";
import { useUpdateDisplayedPageNumber } from "../../Page";
import EditRhese from "./EditRhese";
import { hasCloseSpace } from "./tools";

type RenderRhesesProps = {
  projectId: string;
  allPages: SubToPages_pages[];
};

function RhesesRenderer({ projectId, allPages }: RenderRhesesProps) {
  const { page, pageMetadata } = usePageData(allPages);
  const [applyUpdateTextContent] = useUpdateTextContent();
  const [applyCreateRheseFromPosition] = useCreateRheseFromPosition();
  const updateDisplayedPageNumber = useUpdateDisplayedPageNumber();

  const mergeRheses = useCallback(
    async (rheses: SubscribeToExtendedPage_extendedPage_rhese[]) => {
      if (!page) {
        return;
      }
      allPages; // Create a rhese that wrap the whole text
      await applyCreateRheseFromPosition({
        variables: {
          projectId,
          stringVersion: newStringVersion,
          start: rheses[0].anchors[0].utf16Start,
          size:
            rheses[rheses.length - 1].anchors[0].utf16Start +
            rheses[rheses.length - 1].anchors[0].utf16Size -
            rheses[0].anchors[0].utf16Start +
            operations.length,
          ignoreTextForAudioSync: rheses[0].data.ignoreTextForAudioSync,
          lineBreakAfter: rheses[rheses.length - 1].data.lineBreakAfter,
        },
      });
    },
    [page, applyUpdateTextContent, projectId, applyCreateRheseFromPosition],
  );

  const splitRhese = useCallback(
    async (
      rheses: SubscribeToExtendedPage_extendedPage_rhese[],
      selectedTextPosition: SelectedTextPosition,
    ) => {
      if (selectedTextPosition.size === 0 || !page) {
        return;
      }

      const operations: UpdateTextContent[] = [];

      const characterBeforeAnchor = pageMetadata.textContent.substring(
        selectedTextPosition.start - (page?.start || 0) - 1,
        selectedTextPosition.start - (page?.start || 0),
      );
      const characterAfterAnchor = pageMetadata.textContent.substring(
        selectedTextPosition.start - (page?.start || 0),
        selectedTextPosition.start - (page?.start || 0) + 1,
      );
      const characterBeforeFocus = pageMetadata.textContent.substring(
        selectedTextPosition.start - (page?.start || 0) + selectedTextPosition.size - 1,
        selectedTextPosition.start - (page?.start || 0) + selectedTextPosition.size,
      );
      const characterAfterFocus = pageMetadata.textContent.substring(
        selectedTextPosition.start - (page?.start || 0) + selectedTextPosition.size,
        selectedTextPosition.start - (page?.start || 0) + selectedTextPosition.size + 1,
      );
      let sizeOffset = 0;
      let startOffset = 0;

      if (characterBeforeAnchor === " " || characterBeforeAnchor === "\xa0") {
        operations.push({
          delete: {
            utf16Start: selectedTextPosition.start - 1,
            utf16Size: 1,
          },
        });
        startOffset -= 1;
      }
      if (characterAfterAnchor === " " || characterAfterAnchor === "\xa0") {
        operations.push({
          delete: {
            utf16Start: selectedTextPosition.start,
            utf16Size: 1,
          },
        });
        sizeOffset -= 1;
      }
      if (characterBeforeFocus === " " || characterBeforeFocus === "\xa0") {
        operations.push({
          delete: {
            utf16Start:
              selectedTextPosition.start + startOffset + selectedTextPosition.size - 1 + sizeOffset,
            utf16Size: 1,
          },
        });
        sizeOffset -= 1;
      }
      if (
        (characterAfterFocus === " " || characterAfterFocus === "\xa0") &&
        selectedTextPosition.start + selectedTextPosition.size <
          rheses[rheses.length - 1].anchors[0].utf16Start +
            rheses[rheses.length - 1].anchors[0].utf16Size
      ) {
        operations.push({
          delete: {
            utf16Start:
              selectedTextPosition.start + startOffset + selectedTextPosition.size + sizeOffset,
            utf16Size: 1,
          },
        });
      }
      let newStringVersion = page.stringVersion;

      if (operations.length) {
        const {
          data: { updateTextContent },
        } = await applyUpdateTextContent({
          variables: {
            projectId,
            stringVersion: page.stringVersion,
            operations,
          },
        });
        newStringVersion = updateTextContent;
      }

      await applyCreateRheseFromPosition({
        variables: {
          projectId,
          stringVersion: newStringVersion,
          start: selectedTextPosition.start + startOffset,
          size: selectedTextPosition.size + sizeOffset,
          ignoreTextForAudioSync: rheses[0].data.ignoreTextForAudioSync,
          lineBreakAfter: rheses[rheses.length - 1].data.lineBreakAfter,
        },
      });
    },
    [
      page,
      pageMetadata?.textContent,
      applyCreateRheseFromPosition,
      projectId,
      applyUpdateTextContent,
    ],
  );

  const contentEditableRef = useRef<HTMLDivElement>(null);
  const [rheseToEdit, setRheseToEdit] = useState<SubscribeToExtendedPage_extendedPage_rhese>();
  const isContentAdministrator = useIsContentAdministrator();

  const canEditProjectContent = useCallback(
    (rhese: SubToPageContent_page_rheses) => {
      // Admin can always edit
      if (isContentAdministrator) {
        return true;
      }

      // Non-admin can edit if the rhese doesn't have associated audio
      const hasAudio = Boolean(rhese.audioRecordingId);
      return !hasAudio;
    },
    [isContentAdministrator],
  );

  const replaceRhese = useCallback(
    async (operations: UpdateTextContent[], newRheseSize) => {
      if (!rheseToEdit) return;
      const rheseStart = rheseToEdit.anchors[0].utf16Start;
      // Find in operation if there is an insert at rhese start
      const insertOpAtRheseStart = operations.find(
        (operation) => operation.insert?.utf16Start === rheseStart,
      );

      if (!page) {
        return;
      }
      const {
        data: { updateTextContent: newStringVersion },
      } = await applyUpdateTextContent({
        variables: {
          projectId,
          stringVersion: page.stringVersion,
          operations,
        },
      });
      if (insertOpAtRheseStart) {
        await applyCreateRheseFromPosition({
          variables: {
            projectId,
            stringVersion: newStringVersion,
            start: rheseStart,
            size: newRheseSize,
            ignoreTextForAudioSync: rheseToEdit?.data.ignoreTextForAudioSync || false,
            lineBreakAfter: rheseToEdit?.data.lineBreakAfter || false,
          },
        });
      }
    },
    [rheseToEdit, page, applyUpdateTextContent, projectId, applyCreateRheseFromPosition],
  );

  const RenderBook = useMemo(() => bookRenderer(), []);
  const { selectedTextPosition, saveSelection, pressingControl } = useTextPosition();

  useEffect(() => {
    const noop = (e: Event) => {
      e.preventDefault();
      return false;
    };
    const ref = contentEditableRef.current;
    ref?.addEventListener("cut", noop, false);
    ref?.addEventListener("paste", noop, false);
    ref?.addEventListener("keydown", noop, false);
    ref?.addEventListener("dragenter", noop, false);
    ref?.addEventListener("dragleave", noop, false);
    ref?.addEventListener("dragover", noop, false);
    ref?.addEventListener("drop", noop, false);
    return () => {
      ref?.removeEventListener("cut", noop);
      ref?.removeEventListener("paste", noop);
      ref?.removeEventListener("keydown", noop);
      ref?.removeEventListener("dragenter", noop);
      ref?.removeEventListener("dragleave", noop);
      ref?.removeEventListener("dragover", noop);
      ref?.removeEventListener("drop", noop);
    };
  }, [contentEditableRef]);

  type TranslationProps = {
    menu: { merge: string; creat: string; split: string };
    edit: string;
  };

  const translation: TranslationProps = useI18nObjectHook(
    "project.existing.editingMode.rhese.index.rhesesRenderer",
  );

  const renderDropdownContent = useCallback(() => {
    const selectedRheses = getSelectedMetadata(pageMetadata.rhese, selectedTextPosition);
    const oneRheseOnly = selectedRheses.length === 1;
    const noTextSelected = selectedTextPosition.size === 0;
    const noSpaceClose = hasCloseSpace(pageMetadata, selectedTextPosition);

    // Get a value indicating if only one paragraph is selected, by checking if the selection starts and ends in the same paragraph
    const onlyOneParagraphSelected = !!pageMetadata.paragraph.find(
      (paragraph: SubscribeToExtendedPage_extendedPage_paragraph) =>
        paragraph.anchors[0].utf16Start <= selectedTextPosition.start &&
        paragraph.anchors[0].utf16Start + paragraph.anchors[0].utf16Size >=
          selectedTextPosition.start + selectedTextPosition.size,
    );
    const selectedText = pageMetadata.textContent.substring(
      selectedTextPosition.start - (page?.start || 0),
      selectedTextPosition.start - (page?.start || 0) + selectedTextPosition.size,
    );

    const isOnlySpaces = selectedText.split("").every(checkIfSpace);
    const isMergeDisabled = !onlyOneParagraphSelected || selectedRheses.length < 2;
    const isNewDisabled = !oneRheseOnly || noTextSelected || isOnlySpaces;
    const isSplitDisabled = !oneRheseOnly || !noTextSelected || !noSpaceClose;

    const items: MenuItem[] = [
      getMenuItem({
        key: "1",
        label: translation.menu.merge,
        disabled: isMergeDisabled,
        onClick: () => mergeRheses(selectedRheses),
      }),
      getMenuItem({
        key: "2",
        label: translation.menu.creat,
        disabled: isNewDisabled,
        onClick: () => splitRhese(selectedRheses, selectedTextPosition),
      }),
      getMenuItem({
        key: "3",
        label: translation.menu.split,
        disabled: isSplitDisabled,
        onClick: () =>
          splitRhese(selectedRheses, {
            start: selectedTextPosition.start + 1,
            size:
              selectedRheses[0].anchors[0].utf16Start +
              selectedRheses[0].anchors[0].utf16Size -
              (selectedTextPosition.start + 1),
          }),
      }),
    ];

    return <Menu items={items} />;
  }, [
    pageMetadata,
    selectedTextPosition,
    page?.start,
    translation.menu.merge,
    translation.menu.creat,
    translation.menu.split,
    mergeRheses,
    splitRhese,
  ]);

  const handleClick = useCallback(() => {
    if (pressingControl) {
      // Replace url pageNumber to the one of the selection
      if (
        pageMetadata.page.data.pageNumber.pageNumber !==
        parseInt(window.location.search.split("=")[1])
      ) {
        updateDisplayedPageNumber(pageMetadata.page.data.pageNumber.pageNumber.toString());
      }

      // Fast mode enabled, getting selection
      const selection = window.getSelection();
      if (!selection || selection.toString().length > 0) return;
      const { focusNode, focusOffset } = selection;
      if (!focusNode || !focusOffset) return;

      const start =
        parseInt(focusNode?.parentElement?.getAttribute("data-absolute-position") || "0") -
        (1 - selection?.anchorOffset);

      // If the start is at the end of a rhese, merge it with the next one
      const rheseStart = pageMetadata.rhese.findIndex(
        (rhese: SubscribeToExtendedPage_extendedPage_rhese) =>
          rhese.anchors[0].utf16Start + rhese.anchors[0].utf16Size === start + 1, // We want to check for the right of the selected letter (end of rhese)
      );
      if (rheseStart > -1) {
        // Check if the position is not at the end of a paragraph
        const paragraphStart = pageMetadata.paragraph.findIndex(
          (paragraph: SubscribeToExtendedPage_extendedPage_paragraph) =>
            paragraph.anchors[0].utf16Start === start,
        );
        if (paragraphStart > -1) return;

        const startRhese = pageMetadata.rhese[rheseStart];
        const endRhese = pageMetadata.rhese[rheseStart + 1];

        if (startRhese && endRhese) {
          mergeRheses([startRhese, endRhese]);
          return;
        }
      } else {
        const spaceClose = hasCloseSpace(pageMetadata, { start, size: 0 });
        if (!spaceClose) return;
        const selectedRhese = getSelectedMetadata(pageMetadata.rhese, { start, size: 0 })?.[0];
        if (!selectedRhese) return;
        splitRhese([selectedRhese], {
          start: start + 1,
          size:
            selectedRhese.anchors[0].utf16Start + selectedRhese.anchors[0].utf16Size - start - 1,
        });
        return;
      }
    } else {
      // Fast mode disabled, saving selection
      saveSelection();
    }
  }, [
    updateDisplayedPageNumber,
    mergeRheses,
    pageMetadata,
    pressingControl,
    saveSelection,
    splitRhese,
  ]);

  return (
    <>
      {rheseToEdit && (
        <EditRhese
          editRhese={replaceRhese}
          onOk={() => setRheseToEdit(undefined)}
          onCancel={() => setRheseToEdit(undefined)}
          rhese={rheseToEdit}
          pageMetadata={pageMetadata}
          open={!!rheseToEdit}
          projectId={projectId}
        />
      )}
      <Dropdown
        trigger={pressingControl ? [] : ["contextMenu"]}
        dropdownRender={renderDropdownContent}>
        <div
          ref={contentEditableRef}
          contentEditable="true"
          suppressContentEditableWarning
          onContextMenu={saveSelection}
          onClick={handleClick}>
          <RenderBook
            rheseWrapper={(rhese: SubToPageContent_page_rheses, renderedRhese: ReactElement) => (
              <span className={style.customRheseWrapper}>
                {renderedRhese}
                {canEditProjectContent(rhese) && (
                  <Button
                    onClick={() => {
                      if (
                        pageMetadata.page.data.pageNumber.pageNumber !==
                        parseInt(window.location.search.split("=")[1])
                      ) {
                        updateDisplayedPageNumber(
                          pageMetadata.page.data.pageNumber.pageNumber.toString(),
                        );
                      }
                      setRheseToEdit(
                        pageMetadata?.rhese.find(
                          (r: SubscribeToExtendedPage_extendedPage_rhese) => r.data.id === rhese.id,
                        ),
                      );
                    }}>
                    {translation.edit}
                  </Button>
                )}
              </span>
            )}
            rheseOffset={pageMetadata.nbOfRheses * 10}
            tooltipLength={true}
            highlightSpaces={pressingControl}
            projectId={projectId}>
            {pageMetadata}
          </RenderBook>
        </div>
      </Dropdown>
    </>
  );
}

// eslint-disable-next-line react/display-name
export function PaginatedRheseEdit({
  allPages,
  projectId,
}: {
  allPages: SubToPages_pages[];
  projectId: string;
}) {
  return (
    <Layout>
      <Layout.Content className={style.root}>
        <SplitContent
          component={RhesesRenderer}
          // onVisibilityChange={onVisibilityChange}
          // @ts-ignore
          props={{
            projectId,
            allPages,
          }}>
          {allPages}
        </SplitContent>
      </Layout.Content>
    </Layout>
  );
}
