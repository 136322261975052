import React, { RefObject, useCallback, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import _ from "lodash";
import { notification, Alert } from "antd";
import * as style from "./style.less";
import { Spinner } from "../../../../../components/Spinner";
import { useRhesesSelector } from "../../../Components/RhesesAndAudioRecorder/RhesesAndAudioRecorder";
import { useDeleteAudioReviews } from "../../../Subscriptions/subscriptions";
import Rheses from "./Rheses/Rheses";
import { useI18n } from "../../../../../i18n";
import AudioPlayer from "./AudioPlayer/AudioPlayer";
import { UseAudioModeDataArgs } from "./useAudioModeData";
import useProofListeningNav from "./useProofListeningNav";
import { SpeedControlsProvider } from "./SpeedControls/context";
import useAudioPlayerMachine from "./AudioPlayer/audioPlayerMachine/useAudioPlayerMachine";
import { usePrevious } from "../../../../editor/utils/common";
import { useIsAdmin } from "../../../../../identity";
import {
  SubscribeToExtendedPage_extendedPage,
  SubscribeToExtendedPage_extendedPage_rhese_data_audioInfo,
  SubscribeToExtendedPage_extendedPage_rhese_data_audioRecording,
} from "../../../../editor/Project/api/__generated__/SubscribeToExtendedPage";
import { SubToPages_pages } from "../../../../editor/Project/api/SubToPages";

type Props = UseAudioModeDataArgs & {
  title: React.ReactNode;
  scrollRef: RefObject<HTMLElement>;
  maybeAddAudioReview: (id: string) => void;
  allPages: SubToPages_pages[];
  pageMetadata: SubscribeToExtendedPage_extendedPage;
};

const alertDataHasChanged = _.throttle(
  (message: string) => {
    notification.info({
      message,
    });
  },
  10000,
  { leading: true, trailing: false },
);

export default function ProofListening({
  bookInfoFromUrl,
  currentPageId,
  title,
  scrollRef,
  maybeAddAudioReview,
  pageMetadata,
  allPages,
}: Props) {
  const [t] = useI18n();

  const fullyRecorded = useMemo(() => {
    return pageMetadata.rhese.every((r) => !!r.data.audioRecording);
  }, [pageMetadata]);
  const isAdmin = useIsAdmin();

  const groupedRheses = useMemo(() => {
    return pageMetadata.paragraph.map((p) => {
      return {
        paragraphId: p.data.id,
        rheses: pageMetadata.rhese.filter(
          (r) =>
            r.anchors[0].utf16Start >= p.anchors[0].utf16Start &&
            r.anchors[0].utf16Start + r.anchors[0].utf16Size <=
              p.anchors[0].utf16Start + p.anchors[0].utf16Size,
        ),
      };
    });
  }, [pageMetadata.paragraph, pageMetadata.rhese]);

  /**
   * Nav
   */
  const { nav } = useProofListeningNav(currentPageId, bookInfoFromUrl, allPages);

  /**
   * Requests
   */
  const [deleteAudioReviews] = useDeleteAudioReviews();

  const [selectedRheses, select, selectionAnchor] = useRhesesSelector(pageMetadata.rhese || null);

  /**
   * Selection Logic
   */
  const resetSelection = useCallback(() => {
    select(undefined);
  }, [select]);

  const resetSelectionStatus = useCallback(async () => {
    try {
      const rheseIds = selectedRheses.map((r) => r.data.id);
      await deleteAudioReviews({
        variables: {
          rheseIds,
          projectId: bookInfoFromUrl.bookId,
        },
      });
      resetSelection();
    } catch (e) {
      console.error("resetSelectionStatus error: ", e);
    }
  }, [selectedRheses, deleteAudioReviews, bookInfoFromUrl.bookId, resetSelection]);

  const onReachedEnd = useCallback(
    (eventName, _context, previousContext, event) => {
      if (eventName === "Reached End" && event?.data?.currentRheseId) {
        maybeAddAudioReview(event.data.currentRheseId);
      }
    },
    [maybeAddAudioReview],
  );

  const audioRecordings = useMemo(() => {
    return pageMetadata.rhese
      .map((r) => r.data.audioRecording)
      .filter((o): o is SubscribeToExtendedPage_extendedPage_rhese_data_audioRecording => !!o);
  }, [pageMetadata]);

  const audioPlayerMachine = useAudioPlayerMachine(onReachedEnd);
  const [audioElement] = useState(new Audio());

  const audioInfo = pageMetadata.rhese
    .filter((r) => !!r.data.audioRecording?.fileUuid)
    .map((v) => v.data.audioInfo)
    .filter((v): v is SubscribeToExtendedPage_extendedPage_rhese_data_audioInfo => v !== null)
    .map(({ start, end, humanEnd, humanStart }) => ({ start, end, humanEnd, humanStart }));

  const previousAudioInfo = usePrevious(audioInfo);

  const previousAudioRecodings = usePrevious(audioRecordings);

  // On mount: load resources
  const messageDataChanged = t("proofListeningDataChanged");
  useEffect(() => {
    if (
      _.isEqual(previousAudioRecodings, audioRecordings) &&
      _.isEqual(audioInfo, previousAudioInfo)
    ) {
      return;
    }
    audioPlayerMachine.send([
      {
        type: "Reset",
        callback: () => {
          const isPlaying = audioPlayerMachine.state.matches("READY.PLAYING");
          const isPaused = audioPlayerMachine.state.matches("READY.PAUSED");
          const pristine = !isPlaying && !isPaused;
          if (!pristine) {
            alertDataHasChanged(messageDataChanged);
          }
        },
      },
      {
        type: "Load Resources",
        audioRecordings: audioRecordings,
        rhesesWithPos: pageMetadata.rhese.filter((r) => !!r.data.audioRecording?.fileUuid),
        audioElement,
      },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousAudioRecodings, audioRecordings]);

  // "On load more" effect (pagination)
  useEffect(() => {
    audioPlayerMachine.send({
      type: "On Load More",
      audioRecordings: audioRecordings,
      rhesesWithPos: pageMetadata.rhese.filter((r) => !!r.data.audioRecording?.fileUuid),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedRheses.flat().length]);

  if (!fullyRecorded && !isAdmin) {
    throw new Error("Cannot load proof listening if some audio is missing");
  }

  return (
    <div className={style.root}>
      <>
        {!fullyRecorded && isAdmin && (
          <Alert
            showIcon
            type="warning"
            message={t("proofListening.pageOnlyAccessibleToAdmin")}
            style={{ marginBottom: "1em" }}
          />
        )}
        {title}
        <InfiniteScroll
          useWindow={false}
          getScrollParent={() => scrollRef?.current}
          pageStart={0}
          loadMore={() => {}}
          hasMore={false}
          loader={
            <div key={0} style={{ marginTop: "-70px", marginBottom: "120px" }}>
              <Spinner small />
            </div>
          }>
          <Rheses
            groupedRheses={groupedRheses}
            selectedRheses={selectedRheses}
            selectionAnchor={selectionAnchor}
            setSelectedRhese={select}
            resetSelection={() => select(undefined)}
            resetSelectionStatus={resetSelectionStatus}
            currentRheseId={audioPlayerMachine.currentRheseId}
            isPlaying={audioPlayerMachine.isPlaying}
            isWaiting={audioPlayerMachine.isWaiting}
            projectId={bookInfoFromUrl.bookId}
            pageMetadata={pageMetadata}
          />
        </InfiniteScroll>
      </>
      <SpeedControlsProvider>
        <AudioPlayer previousPage={nav.prev} nextPage={nav.next} audioElement={audioElement} />
      </SpeedControlsProvider>
    </div>
  );
}
