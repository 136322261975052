import React, { createContext } from "react";
import { useInterpret } from "@xstate/react";
import { audioPlayerMachine } from "./audioPlayerMachine";
import { interpret } from "xstate";

const initialAudioPlayerService = interpret(audioPlayerMachine);

export const AudioPlayerMachineContext = createContext({
  audioPlayerService: initialAudioPlayerService,
});

type Props = {
  children: React.ReactNode;
};

export default function AudioPlayerMachineProvider(props: Props) {
  const audioPlayerService = useInterpret(audioPlayerMachine);

  return (
    <AudioPlayerMachineContext.Provider value={{ audioPlayerService }}>
      {props.children}
    </AudioPlayerMachineContext.Provider>
  );
}
