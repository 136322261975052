import { Button, Form, Input, Modal, Row } from "antd";
import React, { useState } from "react";
import { useI18n } from "../../../i18n";
import { useRevokeAccessToProject } from "../Project/api/api";

type RevokeProps = {
  projectId: string;
  close: () => void;
};

export default function Revoke({ projectId, close }: RevokeProps) {
  const [t] = useI18n();
  const [email, setEmail] = useState("");
  const [revokeAccessToProject] = useRevokeAccessToProject();

  return (
    <Modal
      key={projectId}
      centered={true}
      width={"56.66em"}
      title={t("revokeUserAccess")}
      open={projectId !== ""}
      onCancel={close}
      footer={null}>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={() => {
          revokeAccessToProject({ variables: { projectId, userId: email } });
          close();
        }}
        layout="vertical">
        <Form.Item style={{ padding: "0 7em" }} label={t("userEmail")} name="name">
          <Input value={email} onChange={(e) => setEmail(e.target.value)} />
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Row justify="center">
            <Button disabled={email.length === 0} ghost htmlType="submit" shape="round">
              {t("revokeUserAccess")}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
}
