import React, { ReactElement, useMemo, useState } from "react";
import { motion } from "framer-motion";
import Image, { ImageType } from "./Image";
import {
  ExtendedParagraphKind,
  ImagePosition,
} from "../../../../../../../../../../__generated__/globalTypes";
import ImgIcon from "../../../../../../assets/img.svg";
import * as style from "../../../style.less";
import { useI18n } from "../../../../../../../../../i18n";
import { SelectedDialogueSpeaker } from "../../../index";
import { Button } from "antd";
import { SubscribeToExtendedPage_extendedPage_paragraph } from "../../../../../../api/__generated__/SubscribeToExtendedPage";

const DropZone = ({ onMouseEnter }: { onMouseEnter: () => void }) => {
  const [isHover, setIsHover] = useState(false);
  const [t] = useI18n();

  const i18nKey = "project.existing.editingMode.formatting";

  return (
    <motion.div
      onMouseEnter={() => {
        setIsHover(true);
        onMouseEnter();
      }}
      onMouseLeave={() => setIsHover(false)}
      className={style.dropZone}
      animate={isHover ? "hover" : "initial"}
      variants={{ hover: { height: "5em", width: "100%" } }}
      initial={{ height: "0", width: "100%" }}
      transition={{ bounce: 0 }}>
      <motion.div
        variants={{ hover: { opacity: "1" } }}
        initial={{ opacity: "0" }}
        transition={{ bounce: 0 }}>
        {t(`${i18nKey}.dropZone`)}
      </motion.div>
    </motion.div>
  );
};

const DialogueWrapper = ({ children, openDialoguePictureModal, hasSpeaker }: any) => {
  return (
    <motion.div whileHover="hover" className={style.dialogueWrapper}>
      <motion.div
        animate={hasSpeaker ? "hover" : undefined}
        className={style.dialogue}
        variants={{ hover: { opacity: 1 } }}
        initial={{ opacity: 0 }}>
        <Button
          onClickCapture={openDialoguePictureModal}
          className={style.dialogueButton}
          ghost
          shape="circle"
          icon={<ImgIcon />}
        />
      </motion.div>
      {children}
    </motion.div>
  );
};

export type ParagraphWrapperProps = {
  isLastParagraph: boolean;
  paragraphId: string;
  children: ReactElement;
  isDraggingImage: boolean;
  setTargetParagraph: (rheseId: string | null) => void;
  setPosition: (position: ImagePosition) => void;
  deleteImage: (imageId: string) => void;
  editImage: (image: ImageType) => void;
  openDialogueSpeakerModal: (dialogueSpeaker: Partial<SelectedDialogueSpeaker>) => void;
  paragraphs: SubscribeToExtendedPage_extendedPage_paragraph[];
};

const ParagraphWrapper = ({
  isLastParagraph,
  paragraphId,
  children,
  isDraggingImage,
  setTargetParagraph,
  setPosition,
  deleteImage,
  editImage,
  openDialogueSpeakerModal,
  paragraphs,
}: ParagraphWrapperProps): ReactElement | null => {
  const paragraph = useMemo(
    () => paragraphs.find((p) => p.data.id === paragraphId),
    [paragraphId, paragraphs],
  );

  if (!paragraph) {
    // We don't want to throw an error because paragraphs and rheses cant be desync for a short amount of time
    return null;
  }

  const isDialogue = paragraph.data.kind === ExtendedParagraphKind.DIALOGUE;

  let content;
  if (isDraggingImage) {
    content = (
      <motion.div
        onMouseEnter={() => {
          setTargetParagraph(paragraphId);
        }}
        onMouseLeave={() => {
          setTargetParagraph(null);
        }}>
        {!paragraph.data.image?.id && (
          <DropZone onMouseEnter={() => setPosition(ImagePosition.BEFORE)} />
        )}
        {children}
        {isLastParagraph && <DropZone onMouseEnter={() => setPosition(ImagePosition.AFTER)} />}
      </motion.div>
    );
  } else {
    content = children;
  }

  return (
    <>
      {paragraph?.data.image?.position === ImagePosition.BEFORE && (
        <Image
          key={paragraph?.data.image.id}
          deleteImage={deleteImage}
          editImage={editImage}
          description={paragraph?.data.image.description || ""}
          id={paragraph?.data.image.id}
          position={ImagePosition.BEFORE}
          fileName={paragraph?.data.image.fileName}
          kind={paragraph?.data.image.kind}
          figcaption={paragraph?.data.image.figcaption}
        />
      )}
      {isDialogue ? (
        <DialogueWrapper
          hasSpeaker={paragraph.data.speaker?.id !== null}
          openDialoguePictureModal={() =>
            openDialogueSpeakerModal({
              speakerId: paragraph.data.speaker?.id,
              paragraphId: paragraph.data.id,
            })
          }>
          {content}
        </DialogueWrapper>
      ) : (
        content
      )}
      {paragraph?.data.image?.position === ImagePosition.AFTER && (
        <Image
          key={paragraph?.data.image.id}
          deleteImage={deleteImage}
          editImage={editImage}
          description={paragraph?.data.image.description || ""}
          id={paragraph?.data.image.id}
          position={ImagePosition.AFTER}
          fileName={paragraph?.data.image.fileName}
          kind={paragraph?.data.image.kind}
          figcaption={paragraph?.data.image.figcaption}
        />
      )}
    </>
  );
};

export default ParagraphWrapper;
