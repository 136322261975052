{
  "name": "frogeditor",
  "version": "1.12.3",
  "description": "",
  "scripts": {
    "test": "NODE_ENV=test jest --env=jsdom",
    "prebuild": "rm -rf dist",
    "debugbuild": "parcel build ./modules/app/index.html --no-optimize",
    "build": "parcel build ./modules/app/index.html",
    "dev": "parcel ./modules/app/index.html",
    "check": "tsc --noEmit",
    "lint": "eslint --ext .js,.jsx,.ts,.tsx ./modules",
    "download-schema": "./node_modules/graphqurl/bin/run $(cat .env.$NODE_ENV | grep GQL_API_ENDPOINT | cut -d '=' -f2) -H \"authorization: Bearer $TOKEN\" --introspect --format json > schema.json",
    "generate-apollo-types": "npm run download-schema && ./node_modules/apollo/bin/run codegen:generate --addTypename --target=typescript --localSchemaFile=schema.json --includes=modules/**/*"
  },
  "author": "",
  "license": "ISC",
  "devDependencies": {
    "@babel/preset-react": "^7.26.3",
    "@babel/preset-typescript": "^7.26.0",
    "@parcel/css": "^1.14.0",
    "@testing-library/react": "^16.2.0",
    "@types/diff": "^7.0.1",
    "@types/graphql": "^14.5.0",
    "@types/lru-cache": "^7.10.10",
    "@types/react-infinite-scroller": "^1.2.5",
    "@typescript-eslint/eslint-plugin": "^8.22.0",
    "@typescript-eslint/parser": "^8.22.0",
    "apollo": "^2.34.0",
    "babel-eslint": "^10.1.0",
    "buffer": "^6.0.3",
    "eslint": "^9.19.0",
    "eslint-plugin-import": "^2.31.0",
    "eslint-plugin-jsx-a11y": "^6.10.2",
    "eslint-plugin-react": "^7.37.4",
    "eslint-plugin-react-hooks": "^5.1.0",
    "global-jsdom": "^26.0.0",
    "graphqurl": "^2.0.0",
    "jest": "^29.7.0",
    "jest-css-modules": "^2.1.0",
    "jest-css-modules-transform": "^4.4.2",
    "jsdom": "^26.0.0",
    "less": "^4.2.2",
    "postcss": "^8.5.1",
    "postcss-modules": "^6.0.1",
    "process": "^0.11.10",
    "serve": "^14.2.4",
    "svgo": "^2.8.0",
    "typescript": "^5.7"
  },
  "dependencies": {
    "@ant-design/icons": "^5.6.0",
    "@apollo/client": "^3.12.8",
    "@parcel/transformer-less": "^2.13.3",
    "@parcel/transformer-svg-react": "^2.13.3",
    "@sentry/react": "^8.53.0",
    "@sentry/tracing": "^7.120.3",
    "@supabase/auth-ui-react": "^0.4.7",
    "@supabase/auth-ui-shared": "^0.1.8",
    "@supabase/supabase-js": "^2.48.1",
    "@svgr/parcel-plugin-svgr": "^5.5.0",
    "@swc/helpers": "^0.5.15",
    "@types/jest": "^29.5.14",
    "@types/jsdom": "^21.1.7",
    "@types/jwt-decode": "^3.1.0",
    "@types/lodash": "^4.17.15",
    "@types/react": "^19.0.8",
    "@types/react-dom": "^19.0.3",
    "@types/react-lazyload": "^3.2.3",
    "@types/react-outside-click-handler": "^1.3.4",
    "@types/uuid": "^10.0.0",
    "@xstate/react": "^3.2.2",
    "antd": "^5.23.3",
    "autoprefixer": "^10.4.20",
    "classnames": "^2.5.1",
    "colors": "^1.4.0",
    "diff": "^7.0.0",
    "file-saver": "^2.0.5",
    "framer-motion": "^12.0.6",
    "get-blob-duration": "^1.2.0",
    "history": "^5.3.0",
    "i18next": "^24.2.2",
    "i18next-browser-languagedetector": "^8.0.2",
    "i18next-http-backend": "^3.0.2",
    "jwt-decode": "^4.0.0",
    "less-loader": "^12.2.0",
    "lodash": "^4.17.21",
    "lru-cache": "^11.0.2",
    "moment": "^2.30.1",
    "parcel": "^2.13.3",
    "public": "^0.1.5",
    "react": "^18.3.1",
    "react-contenteditable": "^3.3.7",
    "react-detectable-overflow": "^0.8.0",
    "react-dom": "^18.3.1",
    "react-i18next": "^11.18.6",
    "react-infinite-scroller": "^1.2.6",
    "react-intersection-observer": "^9.15.1",
    "react-outside-click-handler": "^1.3.0",
    "react-router": "^6.22.0",
    "react-router-dom": "^6.22.0",
    "regenerator-runtime": "^0.14.1",
    "style.css": "^1.0.3",
    "usehooks-ts": "^3.1.0",
    "uuid": "^11.0.5",
    "xml-formatter": "^3.6.4",
    "xstate": "^4.38.3"
  },
  "overrides": {
    "graphql": "15.8.0"
  },
  "targets": {
    "default": {
      "context": "browser"
    }
  },
  "@parcel/transformer-css": {
    "cssModules": true
  }
}
