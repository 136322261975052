import React, { ReactElement, useMemo } from "react";
import { SubToPages_pages } from "../../../../editor/Project/api/SubToPages";
import { FrogRoles, useIsAdmin } from "../../../../../identity";
import { SubscribeToExtendedPage_extendedPage } from "../../../../editor/Project/api/__generated__/SubscribeToExtendedPage";
import { usePageContext } from "../../../../editor/Project/components/EditingRouter/components/PageContentLoader";
import { Definitions } from "../Definitions/DefinitionsList";
import PageProofListeningProvider from "../ProofListening/PageProofListeningProvider";
import Centered from "../../../../../components/Centered/Centered";
import { Button, Result } from "antd";
import { AudioMutedOutlined } from "@ant-design/icons";
import { useI18n } from "../../../../../i18n";

type ProofListenerContentProps = {
  currentPageId: string;
  bookId: string;
  bookInfoFromUrl: {
    bookId: string;
    currentPage: string;
  };
  scrollRef: React.RefObject<HTMLDivElement>;
  pages: SubToPages_pages[];
  selectedRole: FrogRoles;
  title: ReactElement;
  goBack: () => void;
};

const ProofListenerContent = ({
  currentPageId,
  bookId,
  bookInfoFromUrl,
  scrollRef,
  pages,
  selectedRole,
  title,
  goBack,
}: ProofListenerContentProps) => {
  const [t] = useI18n();
  // @ts-ignore
  const { extendedPage: pageMetadata }: { extendedPage: SubscribeToExtendedPage_extendedPage } =
    usePageContext();
  const isAdmin = useIsAdmin();

  const fullyRecorded = useMemo(() => {
    return pageMetadata?.rhese.every((r) => !!r.data.audioRecording);
  }, [pageMetadata]);

  if (!fullyRecorded && !isAdmin) {
    return (
      <Centered>
        <Result
          icon={<AudioMutedOutlined />}
          title={<p>{t("proofListening.pageNotFullyRecordedTitle")}</p>}
          extra={
            <Button ghost onClick={goBack}>
              {t("proofListening.pageNotFullyRecordedButtonLabel")}
            </Button>
          }
        />
      </Centered>
    );
  }
  return (
    <div>
      <Definitions
        pageId={currentPageId}
        projectId={bookId}
        selectedRole={selectedRole}
        pageMetadata={pageMetadata}
      />
      <PageProofListeningProvider
        key={bookInfoFromUrl.currentPage}
        bookInfoFromUrl={bookInfoFromUrl}
        currentPageId={currentPageId}
        title={title}
        scrollRef={scrollRef}
        allPages={pages}
        pageMetadata={pageMetadata}
      />
    </div>
  );
};

export default ProofListenerContent;
