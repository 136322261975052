import React from "react";
import { Col, Layout, Row, Tooltip } from "antd";
import LogoFrogEditor from "../../app/narration/Assets/LogoFrogEditor";
import { ClickableWrapper } from "../clickableWrapper/ClickableWrapper";
import { useIdentity } from "../../identity";
import style from "./Header.style.less";
import { useMeta } from "../../app/editor/Project/api/api";

const version = require("../../../package.json").version;

type Props = {
  openPanel: () => void;
};

export default function Header({ openPanel }: Props) {
  const identity = useIdentity();
  const userFirstLetter = identity.email![0];

  const metaRes = useMeta();
  const serverVersion = metaRes.data?.meta.serverVersion || "";

  return (
    <Layout.Header className={style.header}>
      <Row justify="space-between">
        <Row>
          <Tooltip
            title={
              <div className={style.tooltipContent}>
                <Row justify="space-between">
                  <p className={style.version}>app :</p>
                  <p className={style.version}>v{version}</p>
                </Row>
                <Row justify="space-between">
                  <p className={style.version}>server :</p>
                  <p className={style.version}>v{serverVersion}</p>
                </Row>
              </div>
            }>
            <Col>
              <LogoFrogEditor />
            </Col>
          </Tooltip>
        </Row>
        <Col>
          <ClickableWrapper>
            <div className={style.userFirstLetterWrapper} onClick={openPanel}>
              <div className={style.userFirstLetter}>{userFirstLetter}</div>{" "}
            </div>
          </ClickableWrapper>
        </Col>
      </Row>
    </Layout.Header>
  );
}
