import { Select } from "antd";
import React, { useMemo, useState } from "react";
import { useI18n } from "../../../i18n";
import {
  useCanSwitchRole,
  FrogRoles,
  useIdentity,
  useIsEditor,
  useIsNarrator,
  useIsProofListener,
  useSelectedRole,
} from "../..";
import { SideBar } from "../../../components/SideBar";
import * as style from "./style.less";
import UserPanelButton, { UserPanelButtonType } from "./UserPanelButton/BaseButton";
import { supabaseClient, useSupabase } from "../../../authentication/supabase";

const { Option } = Select;

export function useUserPanel() {
  const [isUserPanelOpened, setIsUserPanelOpened] = useState(false);

  return {
    isUserPanelOpened,
    closePanel: () => setIsUserPanelOpened(false),
    openPanel: () => setIsUserPanelOpened(true),
  };
}

export const UserPanel = ({
  isUserPanelOpened,
  closePanel,
}: {
  isUserPanelOpened: boolean;
  closePanel: () => void;
}) => {
  const { state: role, dispatch: setRole } = useSelectedRole();

  const canUserSwitchRole = useCanSwitchRole();
  const isEditor = useIsEditor();
  const isNarrator = useIsNarrator();
  const isProofListener = useIsProofListener();
  const session = useSupabase();
  const [t] = useI18n();
  const identity = useIdentity();
  const { userFirstLetter, userName, userEmail } = useMemo(() => {
    return {
      userFirstLetter: session.user.email![0].toUpperCase(),
      userName: identity?.email,
      userEmail: identity?.email,
    };
  }, [identity]);

  const logOut = () => {
    supabaseClient.auth.signOut();
    sessionStorage.clear();
  };

  return (
    <SideBar isOpen={isUserPanelOpened} close={closePanel}>
      <div className={style.userPanelContainer}>
        <div className={style.userInformationContainer}>
          <div className={style.userFirstLetterContainer} onClick={closePanel}>
            <div className={style.userFirstLetter}>{userFirstLetter}</div>
          </div>
          <div className={style.nameAndEmailContainer}>
            <div /* className={style.userName} */>{userName}</div>
            <div /* className={style.userEmail} */>{userEmail}</div>
          </div>
        </div>
        <div /* className={style.textContainer} */>
          <UserPanelButton className={UserPanelButtonType.primary} text={"Modifier mon profil"} />
          <UserPanelButton
            className={UserPanelButtonType.alert}
            text={"Se déconnecter"}
            onClick={logOut}
          />
        </div>
        <div className={style.roleSwitcher}>
          {canUserSwitchRole && role && (
            <Select
              className={style.roleSelector}
              value={role}
              placeholder={t("select_recorgind_issue_type")}
              onChange={(value) => {
                setRole(value as FrogRoles);
              }}>
              {isEditor && <Option key={FrogRoles.Editor}>{t("editor")}</Option>}
              {isNarrator && <Option key={FrogRoles.Narrator}>{t("narrator")}</Option>}
              {isProofListener && (
                <Option key={FrogRoles.ProofListener}>{t("prooflistener")}</Option>
              )}
            </Select>
          )}
        </div>
      </div>
    </SideBar>
  );
};
