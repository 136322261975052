import { useActor } from "@xstate/react";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { Context as AudioMachineContext } from "./audioPlayerMachine";
import { AudioPlayerMachineContext } from "./AudioPlayerMachineProvider";

export default function useAudioPlayerMachine(
  onEvent?: (
    eventName: string,
    context: AudioMachineContext,
    previousContext: AudioMachineContext | undefined,
    event: any,
  ) => void,
) {
  const { audioPlayerService } = useContext(AudioPlayerMachineContext);

  const [state, send] = useActor(audioPlayerService);

  const event = useMemo(() => state._event, [state._event]);

  useEffect(() => {
    if (!onEvent) {
      return;
    }
    onEvent(event.name, state.context, state.history?.context, event);
    return () => {};
  }, [event, onEvent, state.context, state.history]);

  const isLoadingRessources = state.matches("LOADING ASSETS");
  const isWaiting = state.matches("READY.WAITING");
  const isPaused = state.matches("READY.PAUSED");
  const isPlaying = state.matches("READY.PLAYING");

  const togglePlay = useCallback(() => {
    if (isWaiting) {
      send({
        type: "Play",
      });
    }
    if (isPaused) {
      send({
        type: "Resume",
      });
    }
    if (isPlaying) {
      send({
        type: "Pause",
      });
    }
  }, [isPaused, isPlaying, isWaiting, send]);

  return {
    isInitializing: state.matches("INITIAL"),
    state,
    send,
    // shortcuts
    isLoadingRessources,
    isPaused: isWaiting || isPaused,
    isPlaying,
    isWaiting,
    togglePlay,
    currentRheseId: state.context.currentRheseId,
  };
}
